import InlineDatapoint from "@/components/help-center/custom-tags/InlineDatapoint.vue";

export const inlineDatapoint = {
  render: InlineDatapoint,
  attributes: {
    state: {
      type: String,
    },
  },
};
